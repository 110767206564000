import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

function Head(props) {
  let defaultInfo = {
    description: 'Aplicación para la solicitud de ordenes a domilicio.',
    url: window.location.href,
    image_url: `${props.appUrl}icons/android-icon-192x192.png`
  };

  defaultInfo = {
    ...props,
    title: `${props.companyName} | ${props.title}`
  };

  return (
    <Helmet>
      <title>{defaultInfo.title}</title>
      <meta name='description' content={defaultInfo.description} />
      <meta property='og:description' content={defaultInfo.description} />
      <meta property='og:title' content={defaultInfo.title} />
      <meta property='og:url' content={defaultInfo.url} />
      <meta property='og:image' content={defaultInfo.image} />
    </Helmet>
  );
}

const mapStateToProps = (state) => ({
  companyName: state.companyName,
  appUrl: state.appUrl
});
export default connect(mapStateToProps)(Head);
