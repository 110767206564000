function addZeroToLeft(value) {
  return value > 9 ? value : '0' + value;
}

function dateToString(date) {
  const month = addZeroToLeft(date.getMonth() + 1);
  const day = addZeroToLeft(date.getDate());

  return `${day}-${month}-${date.getFullYear()}`;
}

function datetimeToString(date) {
  const hour = addZeroToLeft(date.getHours() > 12 ? date.getHours() - 12 : date.getHours() === 0 ? 12 : date.getHours());
  const minutes = addZeroToLeft(date.getMinutes());
  const acronym = date.getHours() >= 12 ? 'pm' : 'am';

  return `${dateToString(date)} a las ${hour}:${minutes} ${acronym}`;
}

function orderDate(dateTime) {
  return datetimeToString(new Date(dateTime*1000));
}

export {
  addZeroToLeft,
  dateToString,
  datetimeToString,
  orderDate
}
