import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField, {HelperText, Input} from '@material/react-text-field';
import Checkbox from '@material/react-checkbox';
import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';
import { Headline4 } from '@material/react-typography';
import UOrderApi from '../../services/uorder-api.js';
import Auth from '../../utils/auth.js';
import { addZeroToLeft } from '../../filters/date';

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: Object.assign({}, {
          first_name: '',
          last_name: '',
          birthdate: '',
          mobile: '',
          email: '',
          password: '',
          confirmation_password: '',
          accepted_terms_and_conditions: false
        }, this.props.customer
      )
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ customer: Object.assign({}, this.state.customer, { [name]: value }) });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.customer.password !== this.state.customer.confirmation_password) {
      this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Las contraseñas no coinciden!', type: 'error' } });
    } else if (!this.state.customer.accepted_terms_and_conditions) {
      this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Debes aceptar los terminos y condiciones del servicio.', type: 'error' } });
    } else {
      const customer = {
        first_name: this.state.customer.first_name,
        last_name: this.state.customer.last_name,
        birthdate: this.state.customer.birthdate,
        address: this.state.customer.address,
        mobile: this.state.customer.mobile,
        email: this.state.customer.email,
        password: this.state.customer.password,
        confirmation_password: this.state.customer.confirmation_password,
        accepted_terms_and_conditions: this.state.customer.accepted_terms_and_conditions,
        company_id: this.props.companyId
      };

      this.registerCustomer(customer);
    }
  }

  registerCustomer(customer) {
    UOrderApi.customers.create(this.props.$http, customer)
      .then((response) => {
        Auth.authenticateCustomer(this, response.jwt);

        this.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Te has registrado exitosamente!, por favor agrega tu dirección.' } });
        this.props.history.push('/address/new');
        this.props.dispatch({ type: 'hidePreLoader' });
      })
      .catch((error) => {
        this.props.dispatch({ type: 'hidePreLoader' });
        this.props.dispatch({ type: 'openSnackbar', snackbar: { message: error.response && error.response.data ? error.response.data : 'No se han podido registrar tus datos. Por favor intenta más tarde.', type: 'error' } });
        console.error('Error creating customer: ', error);
      });
  }

  render() {
    const currentDate = new Date();
    const minBirthdate = `${currentDate.getFullYear()-18}-${addZeroToLeft(currentDate.getMonth() + 1)}-${addZeroToLeft(currentDate.getDate())}`;

    return (
      <form onSubmit={this.handleSubmit}>
        <Headline4 className="my-2">Nuevo Registro</Headline4>
        <div className="mb-1">
          <TextField
            outlined
            label='Nombres'
            className="w-100 my-2"
          ><Input
            name="first_name"
            value={this.state.customer.first_name}
            onChange={this.handleInputChange}
            required={true}
          />
          </TextField>

          <TextField
            outlined
            label='Apellidos'
            className="w-100 mb-2"
          ><Input
            name="last_name"
            value={this.state.customer.last_name}
            onChange={this.handleInputChange}
            required={true}
          />
          </TextField>

          <TextField
            outlined
            label="Fecha de Nacimiento"
            helperText={<HelperText className="mb-2">Fecha de Nacimiento (dia/mes/año)</HelperText>}
            className="w-100 mb-1"
          ><Input
            type="date"
            name="birthdate"
            value={this.state.customer.birthdate || minBirthdate}
            onChange={this.handleInputChange}
            required={true}
            max={minBirthdate}
          />
          </TextField>

          <hr />

          <TextField
            outlined
            label='Celular'
            className="w-100 mb-2"
          ><Input
            type="tel"
            name="mobile"
            value={this.state.customer.mobile}
            onChange={this.handleInputChange}
            required={true}
            minLength={10}
            maxLength={10}
          />
          </TextField>

          <TextField
            outlined
            label='Email'
            className="w-100 mb-2"
            leadingIcon={<MaterialIcon icon="email"/>}
          ><Input
            type="email"
            name="email"
            value={this.state.customer.email}
            onChange={this.handleInputChange}
            required={true}
          />
          </TextField>

          <hr />

          <TextField
            outlined
            label='Contraseña'
            className="w-100"
            helperText={<HelperText className="mb-2">Debe contener al menos 8 digitos</HelperText>}
            leadingIcon={<MaterialIcon icon="vpn_key"/>}
          ><Input
            type="password"
            name="password"
            value={this.state.customer.password}
            onChange={this.handleInputChange}
            required={true}
            minLength={8}
          />
          </TextField>

          <TextField
            outlined
            label='Confirmación de Contraseña'
            className="w-100"
            helperText={<HelperText>Debe contener al menos 8 digitos</HelperText>}
            leadingIcon={<MaterialIcon icon="vpn_key"/>}
          ><Input
            type="password"
            name="confirmation_password"
            value={this.state.customer.confirmation_password}
            onChange={this.handleInputChange}
            required={true}
            minLength={8}
          />
          </TextField>

          <div className="d-flex align-items-center">
            <Checkbox
              nativeControlId='terms-and-conditions'
              checked={this.state.customer.accepted_terms_and_conditions}
              onChange={(e) => this.setState({customer: Object.assign({}, this.state.customer, { accepted_terms_and_conditions: e.target.checked } )})}
            />
            <label htmlFor='terms-and-conditions' style={{cursor: 'pointer'}}>Acepto los Terminos y Condiciones</label>
          </div>
        </div>

        <Button raised className="w-100"><MaterialIcon icon="how_to_reg" />&nbsp;Registrarse</Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  $http: state.$http,
  companyId: state.companyId
});
export default connect(mapStateToProps)(withRouter(CustomerForm));
