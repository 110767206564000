import UOrderApi from '../services/uorder-api';

const CUSTOMER_KEY = 'uorderCustomer';

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

function getCustomerInfo(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(b64DecodeUnicode(base64));
}

export default {
  setCurrentCustomer(context, customer) {
    context.props.dispatch({ type: 'updateCustomer', customer: customer });
  },

  login(context, credentials, redirect = null) {
    UOrderApi.customers.login(context.props.$http, credentials)
      .then((response) => {
        this.authenticateCustomer(context, response.jwt);

        if(redirect) {
          context.props.history.push(redirect);
          context.props.dispatch({ type: 'hidePreLoader' });
        }

        context.props.dispatch({ type: 'loadLastAddress' });
      }).catch((error) => {
        console.error('Error logging in as customer: ', error.response);
        context.props.dispatch({ type: 'hidePreLoader' });
        context.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'No se ha podido autenticar el usuario.', type: 'error' } });
      })
  },

  authenticateCustomer(context, jwt) {
    let authenticatedHttp = context.props.$http;

    localStorage.setItem(CUSTOMER_KEY, jwt);

    context.props.dispatch({ type: 'updateSignedCustomer', signedCustomer: Object.assign(getCustomerInfo(jwt), { authenticated: true }) });

    authenticatedHttp.defaults.headers.Authorization = this.getAuthToken();
    context.props.dispatch({ type: 'updateHttpConnector', $http: authenticatedHttp });
  },

  logout(context) {
    this.logoutWithoutRedirection(context);
    context.props.history.push('/');
  },

  logoutWithoutRedirection(context) {
    localStorage.removeItem(CUSTOMER_KEY);
    context.props.dispatch({ type: 'updateSignedCustomer', signedCustomer: { authenticated: false } });
    context.props.dispatch({ type: 'setAddress', selectedAddress: {} });
    context.props.dispatch({ type: 'openSnackbar', snackbar: { message: 'Gracias, vuelve pronto!' } });
    try {
      let noAuthenticatedHttp = context.props.$http;
      delete noAuthenticatedHttp.defaults.headers.Authorization;
      context.props.dispatch({ type: 'updateHttpConnector', $http: noAuthenticatedHttp });
    } catch (error) {
      console.error('Error removing authorization: ', error);
    }
  },

  isAuthenticated(store) {
    const jwt = localStorage.getItem(CUSTOMER_KEY);
    if(jwt) {
      store.dispatch({ type: 'updateSignedCustomer', signedCustomer: Object.assign(getCustomerInfo(jwt), { authenticated: true }) });
      return true;
    }

    return false;
  },

  getAuthToken() {
    return 'Bearer ' + localStorage.getItem(CUSTOMER_KEY);
  }
}
