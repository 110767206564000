import '../../styles/splash-screen.css';
import '@material/react-linear-progress/dist/linear-progress.css';
import LinearProgress from '@material/react-linear-progress';
import React, { Component } from 'react';
import splash from '../../assets/images/imagotipo.png';

function splashImage() {
  return (
    <div className="splash-screen text-center">
      <div>
        <img src={splash} alt="Cargando..." />
        <LinearProgress indeterminate={true} />
      </div>
    </div>
  );
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    async componentDidMount() {
      const axios = require('axios');
      let self = this;
      let link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.onload = function() {
        setTimeout(() => {
          self.setState({
            loading: false
          });
        }, 500);
      }

      axios.get(process.env.REACT_APP_API_URL + '/v1/companies/' + process.env.REACT_APP_COMPANY_ID + '/theme')
        .then((response) => {
          link.href = '/themes/' + response.data.theme + '.css';
          document.querySelector('head').appendChild(link);
        })
        .catch((error) => {
          link.href = '/themes/red_orange.css';
          document.querySelector('head').appendChild(link);
          console.error('Error loading theme style: ', error);
        });
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return splashImage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;
