import React from 'react';
import { convertToCurrency } from '../filters/currency';

const calculateDeliveryCost = function (company) {
  let deliveryCost = company.default_delivery_cost;
  if (company.variable_delivery_cost) {
    deliveryCost = <span data-toggle="tooltip" title="El costo será calculado una vez la orden sea aceptada.">(Pendiente)</span>;
  } else {
    deliveryCost = convertToCurrency(deliveryCost);
  }

  return deliveryCost;
}

const formatDeliveryTime = function (company) {
  const ddt = company.default_delivery_time;
  if (ddt >= 60) {
    const hours = Math.floor(ddt / 60);
    if (ddt % 60 === 0) {
      return `${hours} hr`;
    } else {
      const minutes = Math.floor(ddt % 60);
      return `${hours}h:${minutes}m`;
    }
  }

  return `${ddt} min`;
}

const formatDescription = function (product, ignoreChecked = false) {
  let description = '';
  if (product.additionals) {
    for (let i = 0; i < product.additionals.length; i++) {
      const additional = product.additionals[i];
      if (additional.checked || ignoreChecked) {
        if (description.length > 0) {
          description += ', ';
        }
        description += additional.name;
      }
    }
  }

  if (product.observations) {
    for (let i = 0; i < product.observations.length; i++) {
      const observation = product.observations[i];
      if (observation.checked || ignoreChecked) {
        if (description.length > 0) {
          description += ', ';
        }
        description += observation.name;
      }
    }
  }

  if (description.length === 0) {
    description = 'Con todo y sin adiciones.';
  }

  return description;
}

export { calculateDeliveryCost, formatDeliveryTime, formatDescription };
