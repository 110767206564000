import { createStore } from 'redux';
import Db from './db';
import Auth from './auth';
import http from './http';
import UOrderApi from '../services/uorder-api';
import Axios from 'axios';
import swal from 'sweetalert';

// Initial State
const initialState = {
  $http: http({
    validateStatus (status) {
      if (status === 401) {
        Auth.logout(store);
      }
      return status >= 200 && status < 300;
    }
  }),
  branches: [],
  categories: [],
  appUrl: process.env.REACT_APP_URL,
  companyId: process.env.REACT_APP_COMPANY_ID,
  company: {},
  countSelectedProducts: 0,
  Db: null,
  drawerIsOpened: false,
  isLoading: false,
  localities: [],
  productIndex: 0,
  products: [],
  searchTerm: '',
  selectedCategory: 0,
  selectedAddress: {},
  selectedLocality: null,
  selectedBranch: {},
  selectedProducts: [],
  signedCustomer: {},
  snackbar: null,
  companyName: '',
  position: {}
}

// Functions
function showAlert(snackbar, message, type = 'success') {
  if (snackbar && snackbar !== undefined) {
    let snackSurface = document.querySelector('.mdc-snackbar').querySelector('.mdc-snackbar__surface');
    snackSurface.classList.remove('snackbar--error', 'snackbar--warning');
    switch (type) {
      case 'error':
        snackSurface.classList.add('snackbar--error');
        break;
      case 'warning':
        snackSurface.classList.add('snackbar--warning');
        break;
      default:
        break;
    }

    snackbar.labelText = message;
    snackbar.open();
  }
}

function loadCompanyInfo(store, http) {
  UOrderApi.companies.show(http, process.env.REACT_APP_COMPANY_ID)
    .then((company) => {
      store.dispatch({ type: 'setCompany', company: company });
    })
    .catch((error) => {
      console.error('Error getting company info: ', error.response);
    });
}

function loadCategories(store, http) {
  UOrderApi.categories.index(http, process.env.REACT_APP_COMPANY_ID)
    .then((categories) => {
      store.dispatch({ type: 'setCategories', categories: categories });
      if (categories && categories.length > 0) {
        store.dispatch({ type: 'selectCategory', categoryIndex: 0 });
      }
    })
    .catch((error) => {
      console.error('Error getting categories: ', error.response);
      store.dispatch({ type: 'hidePreLoader' });
    });
}

function loadProducts(store, http, params = {}) {
  UOrderApi.products.index(http, process.env.REACT_APP_COMPANY_ID, params)
    .then((products) => {
      store.dispatch({ type: 'setProducts', products: products });
      store.dispatch({ type: 'hidePreLoader' });
    })
    .catch((error) => {
      console.error('Error getting products: ', error.response);
      store.dispatch({ type: 'hidePreLoader' });
    });
}

function loadBranches(store, http, localityId, position = {}) {
  UOrderApi.branches.index(http, process.env.REACT_APP_COMPANY_ID, localityId, position)
    .then((branches) => {
      store.dispatch({ type: 'setBranches', branches: branches });
      if (branches.length > 0) {
        let selectedBranch = branches[0];
        branches.forEach((branch) => {
          if (branch.nearest) {
            selectedBranch = branch;
          }
        });
        store.dispatch({ type: 'selectBranch', selectedBranch: selectedBranch });
      }
      store.dispatch({ type: 'hidePreLoader' });
    })
    .catch((error) => {
      console.error('Error getting Branch Offices: ', error);
      store.dispatch({ type: 'hidePreLoader' });
    });
}

function loadLocalities(store, http, position = {}) {
  UOrderApi.localities.index(http, process.env.REACT_APP_COMPANY_ID, position)
    .then((localities) => {
      store.dispatch({ type: 'setLocalities', localities: localities });
      if (localities.length > 0) {
        let selectedLocalityId = localities[0].id;
        localities.forEach((locality) => {
          if (locality.nearest) {
            selectedLocalityId = locality.id;
          }
        });
        store.dispatch({ type: 'selectLocality', localityId: selectedLocalityId });
      }
    })
    .catch((error) => {
      console.error('Error getting localities: ', error.response);
      store.dispatch({ type: 'hidePreLoader' });
    });
}

function loadLastAddress(store, http) {
  UOrderApi.addresses.showLast(http)
    .then((address) => {
      store.dispatch({ type: 'setAddress', selectedAddress: address });
    }).catch((error) => {
      console.error('Error loading address: ', error.response);
    })
}

function getCurrentPosition(store) {
  navigator.geolocation.getCurrentPosition((position) => {
    store.dispatch({ type: 'setPosition', position: { latitude: position.coords.latitude, longitude: position.coords.longitude } });
  }, (error) => {
    console.error('Error getting position from HTML5: ', error);
    getCurrentPositionFromGoogle(store);
  });
}

function getCurrentPositionFromGoogle(store) {
  Axios.post('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAO2sgburBqlqlW25qPIEiXWJlvkGj-qyM')
    .then((response) => {
      store.dispatch({ type: 'setPosition', position: { latitude: response.data.location.lat, longitude: response.data.location.lng } });
    })
    .catch((error) => {
      console.error('Error getting position from Google API: ', error);
    });
}

function calculateUserGeolocation(store) {
  navigator.permissions.query({
    name: 'geolocation'
  })
    .then((permission) => {
      if (permission.state === 'granted') {
        getCurrentPosition(store);
      } else {
        swal({
          title: 'Por favor, compartenos tu ubicación',
          text: 'Así podremos enviarte tu pedido desde nuestra sucursal más cercana.',
          icon: 'info',
          buttons: {
            cancel: 'Cancelar',
            confirm: 'Compartir'
          },
          dangerMode: false
        })
          .then((accepted) => {
            if (accepted) {
              getCurrentPosition(store);
            } else {
              getCurrentPositionFromGoogle(store);
            }
          });
      }
    })
    .catch((error) => {
      console.error('Error', error)
    });
}

// Reducer Declaration
function reducer(state = initialState, action) {
  let newState = {};
  let selectedProducts = [];
  let productIndex = 0;
  switch(action.type) {
    case 'LoadCartFromDb':
      newState.selectedProducts = action.selectedProducts;
      newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0);

      productIndex = 0;
      newState.selectedProducts.forEach((p) => {
        if (p.pId > productIndex) {
          productIndex = p.pId;
        }
      });

      newState.productIndex = productIndex;
      break;
    case 'addItemToCart':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length);
      newState.productIndex = state.productIndex + 1;
      action.product.pId = newState.productIndex;
      const product = Object.assign({}, action.product);

      newState.selectedProducts = selectedProducts.concat([product]);
      newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + parseFloat(p.quantity), 0);

      Db.saveProduct(product);
      break;
    case 'removeItemFromCart':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length);

      productIndex = -1;
      selectedProducts.forEach((p, i) => {
        if(p.pId === action.product.pId) {
          productIndex = i;
        }
      });

      if (productIndex >= 0) {
        Db.db.selectedProducts.where({pId: action.product.pId}).delete();

        selectedProducts.splice(productIndex, 1);
        newState.selectedProducts = selectedProducts;
        newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0);
      }
      break;
    case 'emptyCart':
      state.selectedProducts.forEach((p) => {
        Db.db.selectedProducts.where({pId: p.pId}).delete();
      });

      newState.selectedProducts = [];
      newState.countSelectedProducts = 0;
      break;
    case 'updateProduct':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length);

      productIndex = -1;
      selectedProducts.forEach((p, i) => {
        if (p.pId === action.product.pId) {
          productIndex = i;
        }
      });

      if(productIndex >= 0) {
        selectedProducts[productIndex] = action.product;

        newState.selectedProducts = selectedProducts;

        Db.updateProduct(action.product);
        newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0);
      }
        break;
    case 'closeDrawer':
      newState.drawerIsOpened = false;
      break;
    case 'openDrawer':
      newState.drawerIsOpened = true;
      break;
    case 'openSnackbar':
      if (state.snackbar && action.snackbar) {
        showAlert(state.snackbar, action.snackbar.message, action.snackbar.type);
      }
      break;
    case 'setSnackbar':
        newState.snackbar = action.snackbar;
      break;
    case 'loadLastAddress':
      loadLastAddress(store, state.$http);
      break;
    case 'setAddress':
      newState.selectedAddress = action.selectedAddress;
      break;
    case 'selectBranch':
      newState.selectedBranch = action.selectedBranch;
      break;
    case 'setBranches':
      newState.branches = action.branches;
      break;
    case 'setLocalities':
      newState.localities = action.localities;
      break;
    case 'selectLocality':
      newState.selectedLocality = action.localityId;

      if (newState.selectedLocality !== null && newState.selectedLocality > 0 && newState.selectedLocality !== state.selectedLocality) {
        loadBranches(store, state.$http, newState.selectedLocality, state.position);
      } else {
        newState.isLoading = false;
      }
      break;
    case 'setCategories':
      newState.categories = action.categories;
      break;
    case 'selectCategory':
      newState.selectedCategory = action.categoryIndex;
      newState.searchTerm = '';

      if (state.categories && state.categories.length > newState.selectedCategory) {
        const category = state.categories[newState.selectedCategory];

        loadProducts(store, state.$http, { category_id: category.id });
      } else {
        newState.isLoading = false;
      }
      break;
    case 'setCompany':
      newState.company = action.company;
      if (typeof newState.company === 'object' && newState.company.long_name !== undefined) {
        newState.companyName = newState.company.long_name;
      }
      break;
    case 'calculatePosition':
      calculateUserGeolocation(store);
      break;
    case 'setProducts':
      newState.products = action.products.sort((a, b) => (a.name > b.name) ? 1 : -1);
      break;
    case 'searchProduct':
      newState.searchTerm = action.searchTerm;
      newState.selectedCategory = -1;
      newState.isLoading = true;
      loadProducts(store, state.$http, { search: newState.searchTerm });
      break;
    case 'updateSignedCustomer':
      newState.signedCustomer = Object.assign({}, state.signedCustomer, action.signedCustomer);
      break;
    case 'showPreLoader':
      newState.isLoading = true;
      break;
    case 'hidePreLoader':
      newState.isLoading = false;
      break;
    case 'updateUwHttp':
      newState.$http = action.$http;
      break;
    case 'updateHttpConnector':
      newState.$http = action.$http;
      break;
    case 'setIndexedDb':
      newState.Db = action.db;
      break;
    case 'setPosition':
      newState.position = action.position;
      loadLocalities(store, state.$http, newState.position);
      break;
    case 'reloadBranches':
      if (state.localities && state.selectedLocality !== undefined && state.selectedLocality > 0) {
        loadBranches(store, state.$http, newState.selectedLocality, state.position);
      } else {
        loadLocalities(store, state.$http, newState.position);
      }
      break;
    case 'reloadProducts':
      if (state.categories && state.categories.length > state.selectedCategory) {
        const category = state.categories[state.selectedCategory];

        if (typeof category === 'object' && category.id !== undefined) {
          loadProducts(store, state.$http, { category_id: category.id });
        }
      }
      break;
    default:
      newState = state;
      break;
  }

  return Object.assign({}, state, newState);
}

const store = createStore(reducer);

//------- Initializers --------//
loadCompanyInfo(store, store.getState().$http);
loadCategories(store, store.getState().$http);

// Load initial Data from IndexedDB
Db.loadProducts(store);

store.dispatch({ type: 'setIndexedDb', db: Db });

// HTTP request proxy
store.dispatch({
  type: 'updateHttpConnector',
  $http: http({
      validateStatus (status) {
        if (status === 401) {
          Auth.logoutWithoutRedirection({props: store});
        }
        return status >= 200 && status < 300;
      }
    })
});

export default store;
