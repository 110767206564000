import React from 'react';
import { connect } from 'react-redux';
import List, { ListItem, ListItemText } from '@material/react-list';

class CategoryList extends React.Component {
  selectCategory(index) {
    this.props.dispatch({ type: 'showPreLoader' });
    this.props.dispatch({ type: 'selectCategory', categoryIndex: index });
  }

  render() {
    return (
      <List
        singleSelection
        selectedIndex={this.props.selectedCategory}
      >
        {
          this.props.categories.map((category, i) => {
            return (
              <ListItem onClick={() => { this.selectCategory(i) }} key={`${i}-${category.id}`} className={`text-capitalize ${this.props.selectedCategory === i ? 'mdc-list-item--selected' : ''}`}>
                <ListItemText primaryText={category.name} />
              </ListItem>
            );
          })
        }
      </List>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  selectedCategory: state.selectedCategory
});
export default connect(mapStateToProps)(CategoryList);
