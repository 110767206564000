// React
import React from 'react';

// Common components
import withSplashScreen from './components/Common/withSplashScreen.js';
import Aside from './components/Layouts/Aside.js';
import Home from './pages/Home.js';
import Campaign from './components/Common/Campaign';

// Store
import store from './utils/store.js';
import { Provider } from 'react-redux';

// Router
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";

// Styles
import './styles/_bootstrap.scss';
import './styles/global.css';
import './styles/products.css';

// Stats
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

// Common components
const Snackbar = React.lazy(() => import('./components/Layouts/Snackbar.js'));
const ErrorBoundary = React.lazy(() => import('./components/Layouts/ErrorBoundary.js'));
const PrivateRoute = React.lazy(() => import('./components/Common/PrivateRoute.js'));
const Loader = React.lazy(() => import('./components/Layouts/Loader.js'));

// Pages
const NotFound = React.lazy(() => import('./pages/NotFound.js'));
const LogIn = React.lazy(() => import(/* webpackMode: "eager" */'./pages/LogIn.js'));
const SignUp = React.lazy(() => import(/* webpackMode: "eager" */'./pages/SignUp.js'));
const Search = React.lazy(() => import('./pages/Search.js'));
const Branch = React.lazy(() => import(/* webpackMode: "eager" */'./pages/Branch.js'));
const Product = React.lazy(() => import('./pages/Product.js'));
const EditProduct = React.lazy(() => import('./pages/EditProduct.js'));
const Cart = React.lazy(() => import('./pages/Cart.js'));
const Checkout = React.lazy(() => import('./pages/Checkout.js'));
const Info = React.lazy(() => import('./pages/Info.js'));
const OrderStatus = React.lazy(() => import('./pages/OrderStatus.js'));
const MyOrders = React.lazy(() => import('./pages/MyOrders.js'));
const Order = React.lazy(() => import('./pages/Order.js'));
const MyAddresses = React.lazy(() => import('./pages/MyAddresses.js'));
const NewAddress = React.lazy(() => import('./components/Addresses/New.js'));
const EditAddress = React.lazy(() => import('./components/Addresses/Edit.js'));
const Feedback = React.lazy(() => import('./pages/Feedback.js'));

const routes = [
  { path: '/', name: 'Home', Component: Home, exact: true },
  { path: '/branch', name: 'Branch', Component: Branch, exact: true },
  { path: '/login', name: 'Log In', Component: LogIn, exact: true },
  { path: '/sign_up', name: 'Sign Up', Component: SignUp, exact: true },
  { path: '/search', name: 'Search', Component: Search, exact: true },
  { path: '/product/:id', name: 'Product', Component: Product, exact: true },
  { path: '/product/:id/*', name: 'Product', Component: Product, exact: true },
  { path: '/edit_product/:id', name: 'EditProduct', Component: EditProduct, exact: true },
  { path: '/cart', name: 'Cart', Component: Cart, exact: true },
  { path: '/info', name: 'Info', Component: Info, exact: true },
  { path: '/feedback', name: 'Feedback', Component: Feedback, exact: true }
]

const privateRoutes = [
  { path: '/checkout', name: 'Checkout', Component: Checkout, exact: true },
  { path: '/order/:id', name: 'Order', Component: Order, exact: true },
  { path: '/order_status/:id', name: 'OrderStatus', Component: OrderStatus, exact: true },
  { path: '/my_orders', name: 'MyOrders', Component: MyOrders, exact: true },
  { path: '/my_addresses', name: 'MyAddresses', Component: MyAddresses, exact: true },
  { path: '/address/new', name: 'NewAddress', Component: NewAddress, exact: true },
  { path: '/address/edit/:id', name: 'EditAddress', Component: EditAddress, exact: true }
]

/* Google Analytics */
ReactGA.initialize('UA-128259329-2');

/* Google Tag Manager */
const tagManagerArgs = {
  gtmId: 'GTM-NNMZ725'
};
TagManager.initialize(tagManagerArgs);

/* Facebook Pixel */
const options = {
    autoConfig: true,
    debug: false,
};
ReactPixel.init('730610234049165', {}, options);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.pageview(location.pathname)
  ReactPixel.pageView();
  TagManager.dataLayer(tagManagerArgs);
});

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Campaign />
        <Aside />
        <React.Suspense fallback={null}>
          <Loader />
          <ErrorBoundary>
            <Switch>
              {routes.map(({ path, exact, Component }) => (
                <Route key={path} path={path} component={Component} exact={exact} />
              ))}

              {privateRoutes.map(({ path, exact, Component }) => (
                <PrivateRoute key={path} path={path} component={Component} exact={exact} store={store} />
              ))}

              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
          <Snackbar />
        </React.Suspense>
      </Router>
    </Provider>
  );
}

export default withSplashScreen(App);
