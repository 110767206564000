import React from 'react';
import { connect } from 'react-redux';
import SimpleLayout from '../components/Layouts/Simple.js';
import "@material/select/mdc-select.scss";
import { MDCSelect } from '@material/select';
import Button from '@material/react-button';
import MaterialIcon from '@material/react-material-icon';
import Head from '../components/Common/Head';

class Branch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranchIndex: 0
    }

    this.backToHome = this.backToHome.bind(this);
    this.selectLocality = this.selectLocality.bind(this);
    this.selectBranch = this.selectBranch.bind(this);
  }

  componentDidMount() {
    if (this.props.position.constructor !== Object || Object.keys(this.props.position).length === 0) {
      this.props.dispatch({ type: 'showPreLoader' });
      this.props.dispatch({ type: 'calculatePosition' });
    }

    this.initializeMdcSelects();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedBranch) !== JSON.stringify(this.props.selectedBranch) && JSON.stringify(this.props.selectedBranch) !== JSON.stringify(this.props.branches[this.selectedBranchIndex])) {
      this.props.branches.forEach((branch, i) => {
        if (branch.id === this.props.selectedBranch.id) {
          this.setState({ selectedBranchIndex: i });
        }
      });
    }

    this.initializeMdcSelects();
  }

  backToHome() {
    if (this.props.location.state !== undefined && this.props.location.state.from !== undefined) {
      this.props.history.push(this.props.location.state.from.pathname);
    } else {
      this.props.history.push('/');
    }
  }

  initializeMdcSelects() {
    try {
      const selectObjs = [].slice.call(document.querySelectorAll('.mdc-select.branch-office'));

      selectObjs.forEach((selectObj) => {
        new MDCSelect(selectObj);
      });
    } catch (exception) {
      console.error('Error initializing selects: ', exception);
    }
  }

  selectLocality(event) {
    this.props.dispatch({ type: 'selectLocality', localityId: event.target.value });
  }

  selectBranch(event) {
    const target = event.target;

    this.setState({ selectedBranchIndex: target.value });
    this.props.dispatch({ type: 'selectBranch', selectedBranch: this.props.branches[target.value] });
  }

  render() {
    return (
      <SimpleLayout>
        <Head title='Selecciona tu sucursal 💖' />
        <div className="container pt-1 mt-1">
          <div className="narrow-form p-2">
            <div className="mdc-select mdc-select--outlined branch-office w-100 mb-3">
              <i className="mdc-select__dropdown-icon"></i>
              <select value={this.props.selectedLocality !== null ? this.props.selectedLocality : 0} className="mdc-select__native-control" onChange={this.selectLocality} name="locality">
                {
                  this.props.localities.map((locality, i) => {
                    return(
                      <option key={`${i}-branch`} value={locality.id}>{locality.short_name}</option>
                    );
                  })
                }
              </select>
              <div className="mdc-notched-outline">
                <div className="mdc-notched-outline__leading"></div>
                <div className="mdc-notched-outline__notch">
                  <label className="mdc-floating-label">Selecciona tu ciudad</label>
                </div>
                <div className="mdc-notched-outline__trailing"></div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="mdc-select mdc-select--outlined branch-office w-100 mr-1 mb-1">
                <i className="mdc-select__dropdown-icon"></i>
                <select value={this.state.selectedBranchIndex} className="mdc-select__native-control" onChange={this.selectBranch} name="branch">
                  {
                    this.props.branches.map((branch, i) => {
                      return(
                        <option key={`${i}-branch`} value={i}>{branch.long_name}</option>
                      );
                    })
                  }
                </select>
                <div className="mdc-notched-outline">
                  <div className="mdc-notched-outline__leading"></div>
                  <div className="mdc-notched-outline__notch">
                    <label className="mdc-floating-label">Indica tu sitio de preferencia</label>
                  </div>
                  <div className="mdc-notched-outline__trailing"></div>
                </div>
              </div>

              <Button unelevated style={{height: '52px'}} onClick={this.backToHome}>
                <MaterialIcon icon='check' />
              </Button>
            </div>
          </div>

          <hr />

          <div style={{height: '400px', maxHeight: '70vh'}}>
            <iframe style={{border: 0, width: '100%', height: '100%'}} src={this.props.selectedBranch.map_url} height="400" frameBorder="0" allowFullScreen="allowfullscreen" title="Ubicación"></iframe>
          </div>
        </div>
      </SimpleLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  position: state.position,
  selectedLocality: state.selectedLocality,
  localities: state.localities,
  selectedBranch: state.selectedBranch,
  branches: state.branches
});
export default connect(mapStateToProps)(Branch);
