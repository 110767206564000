import React from 'react';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';
import Card, {
  CardPrimaryContent
} from "@material/react-card";
import {
  Headline5,
  Headline6
} from '@material/react-typography';
import { convertToCurrency } from '../../filters/currency';
import SecureImage from '../../components/Common/SecureImage';
import { cutText } from '../../filters/string';

class Chunk extends React.Component {
  get description() {
    return this.props.description !== 'No Aplica' ? this.props.description : '';
  }

  render() {
    return (
      <Card className="d-flex align-items-center justify-content-center product-card" title="Agregar" onClick={this.props.onClick}>
        <CardPrimaryContent className="product m-1">
          <div className="product__preview">
            <SecureImage src={this.props.image} alt="preview" />
          </div>
 
          <Headline5 className="product__name text-capitalize my-0">{cutText(this.props.name, 50)}</Headline5>

          <Headline6 className="product__price my-0">{convertToCurrency(this.props.price)}</Headline6>

          <div className="product__description text-justify">{cutText(this.description)}</div>
          {
            this.props.showAddButton && (
              <div className="product__action ml-2">
                <Fab icon={<MaterialIcon icon='add' />} mini style={{backgroundColor: 'var(--mdc-theme-primary)'}} title="Agregar" />
              </div>
            )
          }
        </CardPrimaryContent>
        <div className="product__custom-footer">
          {this.props.customFooter}
        </div>
      </Card>
    );
  }
}

export default Chunk;
