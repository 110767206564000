import React from 'react';
const images = require.context('../../assets/images', true);

class SecureImage extends React.Component {
  constructor(props) {
    super(props);
    this.notFound = images(`./no-found_${process.env.REACT_APP_NO_FOUND_IMAGE}.png`);
    this.img = React.createRef();

    this.errorImage = this.errorImage.bind(this);
  }

  componentDidMount() {
    if ((!this.props.src || this.props.src.length <= 3) && this.img.current) {
      this.img.current.src = this.notFound;
    }
  }

  errorImage(e) {
    e.target.src = this.notFound;
  }

  render() {
    if (this.props.src === null) {
      return <img src={this.notFound} alt={this.props.alt} className={this.props.className} />
    }

    return (
      <img src={this.props.src} alt={this.props.alt} className={this.props.className} onError={this.errorImage} ref={this.img} />
    );
  }
}

export default SecureImage;
