import React from 'react';
import SimpleLayout from '../components/Layouts/Simple.js';
import CustomerForm from '../components/Customers/_form.js';
import Head from '../components/Common/Head';

function SignUp() {
  return (
    <SimpleLayout>
      <Head title='Registro' />
      <div className="narrow-form p-2">
        <CustomerForm />
      </div>
    </SimpleLayout>
  );
}

export default SignUp;
