import React from 'react';
import ApplicationLayout from '../components/Layouts/Application.js';
import ProductList from '../components/Products/List.js';
import Sidebar from '../components/Layouts/Sidebar.js';
import Head from '../components/Common/Head';

function Home() {
  return (
    <ApplicationLayout>
      <Head title='APP' />
      <div className="home-grid">
        <Sidebar />
        <ProductList />
      </div>
    </ApplicationLayout>
  );
}

export default Home;
